import { useMutation } from 'react-query';

import axios from 'axios';
import { format } from 'date-fns';

import {
  downloadCSV,
  downloadFile,
  formatFilters,
  getApiCoreUrl,
  partialObjectUpdate
} from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Documents`;

const getDocument = id => {
  const url = `${baseUrl}/getDocument/${id}`;
  return axios.get(url).then(res => {
    return res.data;
  });
};

const getDocumentOriginalVersion = id => {
  const url = `${baseUrl}/${id}/original_version`;
  return axios.get(url).then(response => {
    return response.data;
  });
};

const deleteDocuments = data => {
  const ids = data.map(d => `ids=${d}`);
  const url = `${baseUrl}?${ids.join('&')}`;
  return axios.delete(url);
};

const downloadDocuments = (filterData = null, search = '', orderBy = '') => {
  const url = `${baseUrl}/export`;
  const obj = formatFilters(filterData, 10, 0, orderBy, search);
  return axios.post(url, obj).then(response => {
    const currentDate = format(new Date(), 'yyyyMMddHHmmss');
    return downloadCSV(response, currentDate + '_documents.csv');
  });
};

const downloadAllDocuments = () => {
  return axios.get(`${baseUrl}/exportAll`).then(response => {
    const currentDate = format(new Date(), 'yyyyMMddHHmmss');
    return downloadCSV(response, currentDate + '_documents.csv');
  });
};

const uploadDocument = document => {
  const formData = new FormData();
  const url = `${baseUrl}/upload`;
  formData.append('file', document.file);
  formData.append('name', document.name);
  document.labels.forEach(l => formData.append('categoryIds', l.id));
  return axios.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  });
};

const uploadDocumentViaUrl = document =>
  axios.post(`${baseUrl}/uploadUrl`, {
    url: document.url,
    name: document.name,
    categoryIds: document.labels.map(l => l.id)
  });

const renameDocument = updatedFile => {
  const url = `${baseUrl}/${updatedFile.id}`;
  return partialObjectUpdate(url, '/name', updatedFile.name);
};

const updateDocuments = updatedFiles => {
  return axios.put(baseUrl, updatedFiles).then(res => {
    return res.data;
  });
};

const copyDocuments = copiedDocuments => {
  const url = `${baseUrl}/copy`;
  return axios.post(url, copiedDocuments);
};

const recalculateIntelligibility = async documentIds => {
  const url = `${baseUrl}/recalculate_intelligibility`;
  const res = await axios.post(url, documentIds);
  return res.data;
};

const changeLabels = async data => {
  const url = `${baseUrl}/change_labels`;
  const res = await axios.post(url, data);
  return res.data;
};

const downloadReport = (documentId, documentName) => {
  const url = `${baseUrl}/${documentId}/download_report`;
  return axios.get(url).then(() => {
    return downloadFile(axios, url, documentName, '.pdf');
  });
};

const useEditMutation = opts => {
  return useMutation(documentService.updateDocuments, opts);
};

const useDeleteMutation = data => {
  return useMutation(documentService.deleteDocuments, data);
};

const hasActiveUsers = id => {
  const url = `${baseUrl}/has_active_users/${id}`;
  return axios.get(url).then(res => res.data);
};

const getDocumentsBillingInfo = async () => {
  const res = await axios.get(`${baseUrl}/documents_billing_info`);
  return res.data;
};

export const documentService = {
  getDocument,
  getDocumentOriginalVersion,
  deleteDocuments,
  uploadDocument,
  uploadDocumentViaUrl,
  renameDocument,
  updateDocuments,
  copyDocuments,
  downloadDocuments,
  downloadAllDocuments,
  downloadReport,
  recalculateIntelligibility,
  changeLabels,
  useEditMutation,
  useDeleteMutation,
  hasActiveUsers,
  getDocumentsBillingInfo
};
