export const ADVERBS = 'adverbs';
export const CHARACTERS_PER_WORD = 'charactersPerWord';
export const COMPLEX_WORDS = 'complexWords';
export const COMPLEX_SENTENCES = 'complexSentences';
export const COMPLEXITY_SCORE = 'complexityScore';
export const FLESCH_KINKAID = 'Flesch Kinkaid';
export const PARAGRAPH_LENGTH = 'paragraphLength';
export const PASSIVE_VOICE = 'passiveVoice';
export const READING_TIME = 'readingTime';
export const SENTENCE_LENGTH = 'sentenceLength';
export const SENTENCES_PER_PARAGRAPH = 'sentencesPerParagraph';
export const SYLLABLES_PER_WORD = 'syllablesPerWord';
export const WORDS_PER_SENTENCE = 'wordsPerSentence';

export const orderByEnum = {
  none: undefined,
  asc: 'asc',
  desc: 'desc'
};

export const displayBorder = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
  extraLargeModified: 'xlm', // scrollbar on safari takes some 15px, so extraLarge (1920px) breakpoint is never reached
  extraLarge: 'xl'
};

export const documentVersionEnum = {
  SIMPLIFIED_VERSION: 0,
  WITH_EDITS: 1,
  ORIGINAL: 2
};

export const suggestionDensity = ['noComplexity', 'lowPriority', 'mediumPriority', 'highPriority'];

export const intelligibilityValuesEnum = {
  NULL: 0,
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
  LEVEL_5: 5
};

export const suggestionDensityEnum = {
  EMPTY_PARAGRAPH: 0,
  NO_COMPLEXITY: 1,
  LOW_PRIORITY: 2,
  MEDIUM_PRIORITY: 3,
  HIGH_PRIORITY: 4
};

export const intelligibilityLevelDictionary = {
  intelligibility_level_0: intelligibilityValuesEnum.NULL,
  intelligibility_level_1: intelligibilityValuesEnum.LEVEL_1,
  intelligibility_level_2: intelligibilityValuesEnum.LEVEL_2,
  intelligibility_level_3: intelligibilityValuesEnum.LEVEL_3,
  intelligibility_level_4: intelligibilityValuesEnum.LEVEL_4,
  intelligibility_level_5: intelligibilityValuesEnum.LEVEL_5
};

export const userCurrentStatus = {
  INVITED: 1,
  ACTIVE: 2,
  DEACTIVATED: 3,
  LOCKED: 4
};

export const errorPages = {
  underMaintenance: 'underMaintenance',
  comingSoon: 'comingSoon',
  error404: 'error404',
  internalServerError: 'internalServerError'
};

export const authPages = {
  login: 'login',
  register: 'register',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  privacyPolicy: 'privacyPolicy',
  mfaRegister: 'mfaRegister',
  mfaLogin: 'mfaLogin'
};

export const HEADER = {
  TAG_1: 1,
  TAG_2: 2,
  TAG_3: 3
};

export const suggestionActionTypes = [
  'remove_complex_word',
  'remove_complex_sentence',
  'long_word'
];

export const suggestionActionTypesEnum = {
  REMOVE_COMPLEX_SENTENCE: 1,
  LONG_SENTENCE: 2,
  REMOVE_COMPLEX_CONCEPT: 3,
  REMOVE_COMPLEX_WORD: 4,
  LONG_WORD: 5,
  REMOVE_PASSIVE_VOICE: 6,
  REMOVE_ADVERB: 7
};

export const suggestionTargetTypesEnum = {
  WORD: 1,
  SENTENCE: 2,
  PARAGRAPH: 3,
  COMPLEX_ENTITY: 4
};

export const userStatusString = {
  [userCurrentStatus.INVITED]: 'pending',
  [userCurrentStatus.ACTIVE]: 'active',
  [userCurrentStatus.DEACTIVATED]: 'deactivated',
  [userCurrentStatus.LOCKED]: 'locked'
};

export const errorCodes = {
  UNABLE_TO_UPLOAD_DOCUMENT: '70',
  UNACCEPTABLE_DOCUMENT_FORMAT: '73',
  WRONG_2FA_CODE: '172',
  FILE_NAME_TOO_LONG: '205',
  DOCUMENT_MAX_SIZE_LIMIT_REACHED: '80',
  UNABLE_TO_RESTORE_VERSION_FOR_MULTIPLE_USERS: '52',
  NO_ACTIVE_TENANTS: '270',
  USER_NOT_ACTIVE: '177'
};

export const intelligibilitySidebarTabs = {
  INTELLIGIBILITY_INFO_TAB: '1',
  TEXT_DENSITY: '2',
  KEY_ISSUES: '3'
};

export const fileStatusEnum = {
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  FAILED: 'failed'
};

export const chartLegendData = {
  STARTING_SCORE: 'startingScore',
  ALL_CONTENT: 'allContent',
  EDITED: 'edited'
};

export const typesOfContent = {
  ALL_CONTENT: 'allContent',
  EDITED_CONTENT: 'editedContent',
  UNEDITED_CONTENT: 'uneditedContent'
};

export const scoreTypes = {
  STARTING_SCORE: 'startingScore',
  CURRENT_SCORE: 'currentScore'
};

export const filterDropdownOptions = {
  TEAMS: 'teams',
  USERS: 'users',
  LABELS: 'labels',
  INTELLIGIBILITY: 'intelligibility',
  EDITS: 'edits',
  TYPES: 'types',
  EXCLUSIONS: 'exclusions'
};

export const editsStatus = {
  UNEDITED: 1,
  EDITED: 2
};

export const metricTypeEnum = {
  INTELLIGIBILITY_SCORE: 'intelligibilityScore',
  COMPLEXITY_SCORE: 'complexityScore'
};

export const filterOptions = {
  CREATED_BY: 'createdBy',
  EDITED_BY: 'editedBy',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'doesNotContain',
  EQUALS: 'equals',
  DOES_NOT_EQUAL: 'doesNotEqual',
  LEVELS: 'levels'
};

export const dateUnit = {
  DAY: 'day',
  MONTH: 'month'
};

export const dateRangeOptions = {
  ALL_TIME: 'allTime',
  TWELVE_MONTHS: 'twelveMonths',
  SIX_MONTHS: 'sixMonths',
  THREE_MONTHS: 'threeMonths',
  THIRTY_DAYS: 'thirtyDays',
  SEVEN_DAYS: 'sevenDays',
  YESTERDAY: 'yesterday',
  TODAY: 'today',
  CUSTOM: 'custom'
};

export const dateRangeValues = {
  ALL_TIME: 0,
  TWELVE_MONTHS: 1,
  SIX_MONTHS: 2,
  THREE_MONTHS: 3,
  THIRTY_DAYS: 4,
  SEVEN_DAYS: 5,
  YESTERDAY: 6,
  TODAY: 7,
  CUSTOM: 8
};

export const scoreToReachData = [
  { low: 0, high: 30, reach: 10 },
  { low: 31, high: 40, reach: 34 },
  { low: 41, high: 50, reach: 50 },
  { low: 51, high: 60, reach: 58 },
  { low: 61, high: 70, reach: 72 },
  { low: 71, high: 80, reach: 82 },
  { low: 81, high: 100, reach: 90 }
];

export const sourcePage = {
  RISK: 1,
  INTELLIGIBILITY: 2,
  PRODUCTIVITY: 3,
  DOCUMENTS: 4,
  CUSTOM_EXCLUSIONS: 5,
  MY_DOCUMENTS: 6
};

export const sourcePageMapper = {
  [sourcePage.RISK]: 'risk',
  [sourcePage.INTELLIGIBILITY]: 'intelligibility',
  [sourcePage.PRODUCTIVITY]: 'productivity',
  [sourcePage.DOCUMENTS]: 'content'
};

export const importSource = {
  FILES: 1,
  URL: 2
};

export const editorSidebarOptions = {
  SCORING: '1',
  SIMPLIFICATION: '2',
  COMMENTS: '3'
};

export const suggestionImpact = {
  LOW_IMPACT: 1,
  MEDIUM_IMPACT: 2,
  HIGH_IMPACT: 3
};

export const suggestionCategories = {
  COMPLEX_SENTENCE: 1,
  COMPLEX_PHRASE: 2,
  WRITING_STYLE: 3,
  LONG_SENTENCE: 4,
  COMPLEX_CONCEPT: 5
};

export const contentTypes = {
  DOCX: 1,
  PDF: 2,
  URL: 3,
  HTML: 4
};

export const contentStatus = {
  PROCESSING: 0,
  ACTIVE: 1,
  ERROR: 2
};

export const notificationType = {
  REPHRASE_MODE: 1
};

export const suggestionActions = {
  DISMISS_SUGGESTION: 1,
  INCORRECT_SUGGESTION: 2
};

export const exclusionType = {
  STANDARD: 1,
  CUSTOM: 2
};

export const customExclusionType = {
  WORD_OR_PHRASE: 1,
  START_AND_END_TAGS: 2
};

export const exclusionOption = {
  EXCLUDED_FROM_SCORING_AND_SUGGESTIONS: 1,
  EXCLUDED_FROM_SUGGESTIONS: 2,
  DISABLED: 3
};
