import React, { useContext } from 'react';

import { LoadingScreen } from 'components';
import { UserContext, ResponseProvider } from 'providers';
import { Router } from 'routes';
import { GlobalStyles } from 'theme';

import { ProgressBarStyle } from './ProgressBarStyle';
import { ThemePrimaryColor } from './ThemePrimaryColor';

export const App = () => {
  const { initialized } = useContext(UserContext);

  return (
    <>
      <LoadingScreen isLoading={!initialized} />
      {initialized && (
        <ThemePrimaryColor>
          <ResponseProvider>
            <ProgressBarStyle />
            <GlobalStyles />
            <Router />
          </ResponseProvider>
        </ThemePrimaryColor>
      )}
    </>
  );
};
