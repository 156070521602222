'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
const prefix = 'far';
const iconName = 'indent';
const width = 448;
const height = 512;
const aliases = [];
const unicode = 'f03c';
const svgPathData =
  'M0 64C0 77.3 10.7 88 24 88l400 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 40C10.7 40 0 50.7 0 64zM192 192c0 13.3 10.7 24 24 24l208 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-208 0c-13.3 0-24 10.7-24 24zm24 104c-13.3 0-24 10.7-24 24s10.7 24 24 24l208 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-208 0zM0 448c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 424c-13.3 0-24 10.7-24 24zM121 268.4c7.8-6.4 7.8-18.3 0-24.7L26.2 165.6C15.7 157 0 164.4 0 177.9L0 334.1c0 13.5 15.7 20.9 26.2 12.4L121 268.4z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};

exports.faIndent = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
