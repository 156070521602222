import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';
import { checkFeatureFlag, featureFlagsEnum, getParentElementWithTag } from 'helpers';
import { UserContext } from 'providers';
import { faArrowTurnDownLeft } from 'resources/fontawesome/regular/faArrowTurnDownLeft';
import { faArrowTurnDownRight } from 'resources/fontawesome/regular/faArrowTurnDownRight';
import { faBold } from 'resources/fontawesome/regular/faBold';
import { faCommentPlus } from 'resources/fontawesome/regular/faCommentPlus';
import { faIndent } from 'resources/fontawesome/regular/faIndent';
import { faItalic } from 'resources/fontawesome/regular/faItalic';
import { faLink } from 'resources/fontawesome/regular/faLink';
import { faListOl } from 'resources/fontawesome/regular/faListOl';
import { faListUl } from 'resources/fontawesome/regular/faListUl';
import { faMagnifyingGlass } from 'resources/fontawesome/regular/faMagnifyingGlass';
import { faOutdent } from 'resources/fontawesome/regular/faOutdent';
import { faTextSlash } from 'resources/fontawesome/regular/faTextSlash';
import { faUnderline } from 'resources/fontawesome/regular/faUnderline';
import { faSparkles } from 'resources/fontawesome/solid/faSparkles';
import { useStoreFields } from 'stores';

import { useStyles } from './CustomInlineToolbar.css.js';
import { TableMenu } from './TableMenu/TableMenu.js';
import { SearchTermComponent } from '../../DocumentEditPage/SearchTermComponent/SearchTermComponent';

// eslint-disable-next-line func-style
function undoChange() {
  this.quill.history.undo();
  this.quill?.formatText(0, this.quill?.getText().length, 'paragraph_suggestion', false);
}

// eslint-disable-next-line func-style
function redoChange() {
  this.quill.history.redo();
}

//formatting applied on every list item except on those where formatting of numbering is intended
// eslint-disable-next-line func-style
function defaultListItemFormatting(obj, value) {
  // Define a new list id as an incremented max id value from existing lists.
  const items = Array.from(document.getElementsByTagName('list-item'));
  const id = items.length
    ? Math.max(...items.map(li => parseInt(li.getAttribute('listId')))) + 1
    : 1;

  // 'value' can be 'bullet', 'decimal', or false, if selected paragraphs are already list items.
  if (value) {
    obj.quill.format('listItem', `{ "id": "${id}", "type": "${value}"}`, 'user');
  } else {
    obj.quill.format('listItem', false, 'user');
  }
}

//formatting applied on all text except on list numbering
const defaultBoldFormatting = (obj, value, isBoldActive) => {
  // applied styling (bold/italic) for text which is not of type numbered list
  if (value) {
    obj.quill.format(isBoldActive ? 'bold' : 'italic', true, 'user');
  } else {
    obj.quill.format(isBoldActive ? 'bold' : 'italic', false, 'user');
  }
};

// // check if one whole list item is selected
const checkIfWholeListItemIsSelected = (selections, text, selectedListItems) => {
  // array of selected list items text lengths
  const selectedListItemsTextLength = text.map(item => {
    return item.cache.length;
  });
  // sum of new lines between selected list items
  const sumOfNewLinesBetweenListItems = selectedListItemsTextLength.length - 1;
  // sum of selected list items text lengths including new lines
  const selectedListItemsTextLengthSum =
    selectedListItemsTextLength.reduce((partialSum, a) => partialSum + a, 0) +
    sumOfNewLinesBetweenListItems;
  // check if one or more whole numbered list item is selected
  return (
    (selections.length === selectedListItemsTextLengthSum - selectedListItemsTextLength.length ||
      selections.length === selectedListItemsTextLengthSum) &&
    selectedListItems.type !== 'bullet'
  );
};

// styling applied to the list numbering
const findSelectedListItemAndApplyStyling = (
  elementList,
  selectedListItems,
  value,
  isBoldActive
) => {
  selectedListItems.forEach(item => {
    for (const li of elementList) {
      if (li.getAttribute('listItemId') === item.listItemId) {
        if (value) {
          li.classList.add(isBoldActive ? 'bold' : 'italic');
        } else {
          li.classList.remove(isBoldActive ? 'bold' : 'italic');
        }
      }
    }
  });
};

// return list item dom nodes
const findSelectedListItems = obj => {
  const listItemFormats = getFormat(obj).rangeFormats.listItem;
  const items = Array.isArray(listItemFormats) ? [...listItemFormats] : [listItemFormats];
  return items[0] ? [...new Set(items.map(li => JSON.parse(li)))] : {};
};

// apply styling if whole list item is selected or proceed to default behavior
const numberingListItemStyling = (obj, value, isBoldActive) => {
  const listFormats = getFormat(obj);
  const foundSelectedListItems = findSelectedListItems(obj);
  if (listFormats.rangeFormats.listItem) {
    if (
      checkIfWholeListItemIsSelected(
        listFormats.selections,
        listFormats.text,
        foundSelectedListItems
      )
    ) {
      findSelectedListItemAndApplyStyling(
        listFormats.elementList,
        foundSelectedListItems,
        value,
        isBoldActive
      );
    }
  }
  defaultBoldFormatting(obj, value, isBoldActive);
};

// eslint-disable-next-line func-style
function boldListItemNumberingHandler(value) {
  numberingListItemStyling(this, value, true);
}

// eslint-disable-next-line func-style
function italicListItemNumberingHandler(value) {
  numberingListItemStyling(this, value, false);
}

// apply indentation depending on toolbar button click
export const indentationFormat = (obj, value) => {
  if (value === '+1' || value === '-1') {
    obj.quill.format('indent', value, 'user');
  }
};

// eslint-disable-next-line func-style
export function getFormatFunc(obj, item, index, selectedListItemsTextLength) {
  return obj.quill.getFormat({
    index: item,
    length: selectedListItemsTextLength[index]
  });
}

// get indentation level of a list item
const getIndentation = indentationFormatOfEachListItem =>
  parseInt(
    Array.isArray(indentationFormatOfEachListItem.indent)
      ? indentationFormatOfEachListItem.indent[1]
      : indentationFormatOfEachListItem.indent || 0
  );

// set list item selection based on index position, get current indentation, and apply new one
const indentationDisassemble = (
  obj,
  indexPositionsOfEachMarkedListItem,
  selectedListItemsTextLength,
  value
) => {
  indexPositionsOfEachMarkedListItem.forEach((item, index) => {
    setSelection(obj, item, index, selectedListItemsTextLength);
    indentationFormat(obj, value);
  });
};

// eslint-disable-next-line func-style
function setSelection(obj, item, index, selectedListItemsTextLength) {
  obj.quill.setSelection({
    index: item,
    length: selectedListItemsTextLength[index]
  });
}

// check if right max indentation position is reached
// defined in checkIndentationLevel function with number 8
const checkIfMaxIndentationIsReached = (
  obj,
  indexPositionsOfEachMarkedListItem,
  selectedListItemsTextLength
) => {
  return indexPositionsOfEachMarkedListItem.map((item, index) => {
    setSelection(obj, item, index, selectedListItemsTextLength);
    return getIndentation(getFormatFunc(obj, item, index, selectedListItemsTextLength));
  });
};

// stop indentation progress if indentation limit is reached
const checkIndentationLevel = (value, indentationArr) => {
  return value === '+1' && indentationArr.includes(8);
};

// eslint-disable-next-line func-style
function indentationHandler(value) {
  const range = this.quill.getSelection();

  if (range.length === 0) {
    indentationFormat(this, value);
  }

  const text = this.quill.getLines(range.index, range.length);
  const selectedListItemsTextLength = text.map(item => {
    return item.cache.length - 1;
  });
  const indexPositionOfMarkedItems = range.index;
  let currentLength = 0;
  // array of starting index positions of all marked list items
  const indexPositionsOfEachMarkedListItem = selectedListItemsTextLength.map(
    (item, index, thisArray) => {
      if (index) {
        currentLength += thisArray[index - 1] + 1;
      }
      return indexPositionOfMarkedItems + currentLength;
    }
  );
  // array of indentation levels for every marked list item
  const indentationArr = checkIfMaxIndentationIsReached(
    this,
    indexPositionsOfEachMarkedListItem,
    selectedListItemsTextLength
  );

  // stop right indentation if level 8 is reached
  if (checkIndentationLevel(value, indentationArr)) {
    this.quill.setSelection(range);
    return;
  }

  indentationDisassemble(
    this,
    indexPositionsOfEachMarkedListItem,
    selectedListItemsTextLength,
    value
  );
  this.quill.setSelection(range);
}

// apply styling when going from all bolded bullet to all bolded numbered list
const findSelectedListItemAndApplyStylings = (elementList, selectedListItems, formatStyles) => {
  selectedListItems.forEach(item => {
    for (const li of elementList) {
      if (li.getAttribute('listItemId') === item.listItemId) {
        if (formatStyles) {
          li.classList.remove('bullet');
          li.classList.add('decimal');
          if ('bold' in formatStyles) {
            li.classList.add('bold');
          }
          if ('italic' in formatStyles) {
            li.classList.add('italic');
          }
        }
      }
    }
  });
};

// quill methods used on multiple places extracted in one function
const getFormat = obj => {
  const selection = obj.quill.getSelection();
  return {
    rangeFormats: obj.quill.getFormat(),
    selections: obj.quill.getSelection(),
    text: obj.quill.getLines(selection.index, selection.length),
    elementList: document.getElementsByTagName('list-item')
  };
};

const checkIfNumberingStylingIsIntended = (obj, value, formatStyles) => {
  const listFormats = getFormat(obj);
  return (
    listFormats.rangeFormats.listItem &&
    checkIfWholeListItemIsSelected(
      listFormats.selections,
      listFormats.text,
      findSelectedListItems(obj)
    ) &&
    value &&
    value !== 'bullet' &&
    Object.keys(formatStyles).length !== 0
  );
};

// check if whole list item is selected, if bold or italic is applied across those items
// and one of the list buttons is clicked
// eslint-disable-next-line func-style
function listItemHandler(obj, value) {
  const listFormats = getFormat(obj);
  const formatStyles = obj.quill.getFormat(listFormats.selections);
  delete formatStyles.listItem;
  if (checkIfNumberingStylingIsIntended(obj, value, formatStyles)) {
    findSelectedListItemAndApplyStylings(
      listFormats.elementList,
      findSelectedListItems(obj),
      formatStyles
    );
  } else {
    defaultListItemFormatting(obj, value);
  }
}

// eslint-disable-next-line func-style
function listHandler(value) {
  listItemHandler(this, value);
}

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
      'list-item': listHandler,
      bold: boldListItemNumberingHandler,
      italic: italicListItemNumberingHandler,
      indent: indentationHandler
    }
  },
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true
  }
};

// Quill Toolbar component
export const CustomInlineToolbar = ({
  quillEditor,
  isCommentingActive,
  handleCommentsButtonClick,
  search,
  setSearch,
  resultsIndices,
  handleSearchChange,
  handleChangesDown,
  handleChangesUp,
  activeIndex
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const selectionRange = quillEditor?.selection?.getNativeRange();
  const [t] = useTranslation('common');

  const {
    isRephraseSelected,
    setIsRephraseSelected,
    undoRedoStack,
    isRephraseDialogOpen,
    isReplacementDialogOpen,
    selectedText
  } = useStoreFields([
    'isRephraseSelected',
    'setIsRephraseSelected',
    'undoRedoStack',
    'isRephraseDialogOpen',
    'isReplacementDialogOpen',
    'selectedText'
  ]);

  let isTableSelected = null;
  const { featureFlags } = useContext(UserContext);
  if (selectionRange) {
    const selectedNode = selectionRange.start.node;
    isTableSelected = getParentElementWithTag(selectedNode, 'TABLE');
  }

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const QuillIcons = ReactQuill.Quill.import('ui/icons');
  QuillIcons.bold = null;
  QuillIcons.italic = null;
  QuillIcons.underline = null;
  QuillIcons.link = null;
  QuillIcons.align['left'] = null;
  QuillIcons.align['center'] = null;
  QuillIcons.align['right'] = null;
  QuillIcons.align['justify'] = null;
  QuillIcons.list['ordered'] = null;
  QuillIcons.list['bullet'] = null;
  QuillIcons.indent['+1'] = null;
  QuillIcons.indent['-1'] = null;
  QuillIcons.clean = null;

  const Link = ReactQuill.Quill.import('formats/link');
  Link.sanitize = function (url) {
    const protocol = url.slice(0, url.indexOf(':'));
    if (Link.PROTOCOL_WHITELIST.indexOf(protocol) === -1) {
      url = 'https://' + url;
    }
    return url;
  };
  ReactQuill.Quill.register(Link, true);

  const commenting = checkFeatureFlag(featureFlags, featureFlagsEnum.COMMENTING);

  const handleRephrase = () => {
    setIsRephraseSelected(!isRephraseSelected);
  };

  const isSimplificationDialogOpen = isRephraseDialogOpen || isReplacementDialogOpen;
  const commonDisabledCondition =
    isCommentingActive || isRephraseSelected || isSimplificationDialogOpen;

  return (
    <div
      id='toolbar'
      style={{
        minHeight: '36px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: 0,
        marginLeft: '3.5rem',
        width: 'fit-content'
      }}>
      <ClickAwayListener
        onClickAway={() => {
          setIsSearchOpen(false);
          setSearch('');
        }}>
        <span className='ql-formats search-container'>
          <button
            disabled={isRephraseSelected || isSimplificationDialogOpen}
            className={`search button-image ${isSearchOpen && classes.searchButton}`}
            onClick={() => setIsSearchOpen(!isSearchOpen)}>
            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ height: '20px', width: '14px' }} />
          </button>
          {isSearchOpen && (
            <div className='search-input'>
              <SearchTermComponent
                total={resultsIndices.current.length}
                value={search}
                handleChange={handleSearchChange}
                placeholder='Search document'
                activeHighlightIndex={activeIndex}
                handleChangesDown={handleChangesDown}
                handleChangesUp={handleChangesUp}
              />
            </div>
          )}
        </span>
      </ClickAwayListener>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button
          data-testid='undo'
          disabled={!undoRedoStack.undo.length || commonDisabledCondition}
          className='ql-undo'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faArrowTurnDownLeft} style={{ height: '20px', width: '14px' }} />
          </div>
        </button>
        <button
          data-testid='redo'
          disabled={!undoRedoStack?.redo.length || commonDisabledCondition}
          className='ql-redo'>
          <div className={'button-image'}>
            <FontAwesomeIcon
              icon={faArrowTurnDownRight}
              style={{ height: '20px', width: '14px' }}
            />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span
        data-testid='formats'
        disabled={isTableSelected || commonDisabledCondition}
        className='ql-formats'>
        <select className='ql-heading ql-picker' defaultValue=''>
          <option value=''>Normal</option>
          <option value='0'>Heading 1</option>
          <option value='1'>Heading 2</option>
          <option value='2'>Heading 3</option>
        </select>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button disabled={commonDisabledCondition} className='ql-bold' data-testid='bold'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faBold} style={{ height: '14px', width: '11px' }} />
          </div>
        </button>
        <button disabled={commonDisabledCondition} className='ql-italic' data-testid='italic'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faItalic} style={{ height: '14px', width: '11px' }} />
          </div>
        </button>
        <button disabled={commonDisabledCondition} className='ql-underline' data-testid='underline'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faUnderline} style={{ height: '14px', width: '13px' }} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <button disabled={commonDisabledCondition} className='ql-link'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faLink} style={{ height: '14px', width: '18px' }} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span disabled={isTableSelected || commonDisabledCondition} className='ql-formats'>
        <select className='alignment ql-align' defaultValue='left'>
          <option value='left' />
          <option value='center' />
          <option value='right' />
          <option value='justify' />
        </select>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button
          disabled={isTableSelected || commonDisabledCondition}
          className='ql-list-item'
          value='bullet'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faListUl} style={{ height: '14px', width: '14px' }} />
          </div>
        </button>
        <button
          disabled={isTableSelected || commonDisabledCondition}
          className='ql-list-item'
          value='decimal'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faListOl} style={{ height: '14px', width: '14px' }} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button
          disabled={isTableSelected || commonDisabledCondition}
          className='ql-indent'
          value='+1'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faIndent} style={{ height: '14px', width: '13px' }} />
          </div>
        </button>
        <button
          disabled={isTableSelected || commonDisabledCondition}
          className='ql-indent'
          value='-1'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faOutdent} style={{ height: '14px', width: '13px' }} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span disabled={commonDisabledCondition} className='ql-formats'>
        <TableMenu editor={quillEditor} />
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button disabled={commonDisabledCondition} className='ql-clean'>
          <div className={'button-image'}>
            <FontAwesomeIcon icon={faTextSlash} style={{ height: '12px', width: '15px' }} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      {commenting && (
        <>
          <span className='ql-formats'>
            <button
              data-testid='addCommentButton'
              disabled={isRephraseSelected || isSimplificationDialogOpen}
              onClick={handleCommentsButtonClick}
              style={{
                backgroundColor: isCommentingActive && theme.palette.gray.lighter,
                borderRadius: '20px',
                color: theme.palette.gray.dark
              }}>
              <div className={'button-image'}>
                <FontAwesomeIcon icon={faCommentPlus} style={{ height: '14px', width: '14px' }} />
              </div>
            </button>
          </span>
          <span className='ql-formats'>
            <div className={classes.separator}>
              <Icons iconName={'separator'} />
            </div>
          </span>
        </>
      )}
      <span className={`${classes.rephrase} ql-formats`}>
        <button
          data-testid='rephrase'
          disabled={isCommentingActive || isSimplificationDialogOpen || selectedText?.length > 1}
          className={isRephraseSelected ? classes.rephraseSelected : classes.rephraseNotSelected}
          onClick={handleRephrase}
          style={{
            width: 'auto',
            display: 'flex',
            alignItems: 'center'
          }}>
          <div
            className={'button-image'}
            style={{
              marginRight: '8px'
            }}>
            <FontAwesomeIcon icon={faSparkles} />
          </div>
          <div
            style={{
              fontFamily: theme.typography.fontFamilyPrimaryMedium,
              fontSize: theme.typography.pxToRem(14)
            }}>
            {t('rephrase')}
          </div>
        </button>
      </span>
    </div>
  );
};

CustomInlineToolbar.displayName = 'CustomInlineToolbar';

CustomInlineToolbar.propTypes = {
  quillEditor: PropTypes.object,
  isCommentingActive: PropTypes.bool,
  handleCommentsButtonClick: PropTypes.func,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  resultsIndices: PropTypes.object,
  handleSearchChange: PropTypes.func,
  handleChangesDown: PropTypes.func,
  handleChangesUp: PropTypes.func,
  activeIndex: PropTypes.number
};
