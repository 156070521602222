import React, { forwardRef } from 'react';

import { MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { TextFieldStyle } from 'components';

export const FormDropdown = forwardRef(
  (
    {
      menuItemData,
      props,
      inputProps,
      touched,
      errors,
      fieldWidth,
      dataCyAttributes,
      onChange,
      placeholder,
      value
    },
    ref
  ) => {
    const theme = useTheme();

    const handleSelectColor = isTouched => {
      if (isTouched) {
        return theme.palette.gray.dark;
      } else {
        return theme.palette.gray.main;
      }
    };

    const useStyles = makeStyles({
      menuItems: {
        height: '50px !important',
        fontFamily: theme.typography.fontFamilyPrimaryMedium,
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.gray.dark,
        '&:hover': {
          backgroundColor: theme.palette.gray.lighter + ' !important',
          color: theme.palette.gray.dark
        },
        '&:focus': {
          backgroundColor: 'transparent'
        }
      },
      borderBottom: {
        borderBottom: '0.5px solid ' + theme.palette.gray.lighter
      }
    });

    const classes = useStyles();

    return (
      <Stack ref={ref}>
        <TextFieldStyle
          {...props}
          select
          sx={{
            '& .MuiSelect-select span::before': {
              content: `"${value || placeholder}"`
            },
            '& .MuiOutlinedInput-root': {
              bgcolor: theme.palette.gray.white,
              color: handleSelectColor(touched),
              height: '50px',
              fontSize: theme.typography.pxToRem(14),
              '& fieldset': {
                borderColor: theme.palette.gray.light
              },
              '&:hover fieldset': {
                borderColor: theme.palette.gray.main
              },
              '& .MuiSelect-select': {
                fontFamily: theme.typography.fontFamilyPrimaryRegular
              }
            },
            '& .MuiSelect-icon': {
              border: '0px !important'
            },
            width: fieldWidth,
            '& .Mui-focused': {
              border: '1px solid',
              borderColor: theme.palette.gray.main,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              }
            }
          }}
          error={errors && Boolean(errors)}
          helperText={errors}
          onChange={onChange}
          {...inputProps}
          SelectProps={{
            SelectDisplayProps: {
              'data-cy': dataCyAttributes?.dropdown
            }
          }}>
          {menuItemData?.map((item, index) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={{
                marginBottom:
                  menuItemData[menuItemData.length - 1].id === item.id ? '-8px' : undefined
              }}
              className={clsx(
                menuItemData.length - 1 !== index && classes.borderBottom,
                classes.menuItems
              )}
              data-cy={dataCyAttributes?.item + item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextFieldStyle>
      </Stack>
    );
  }
);

FormDropdown.displayName = 'FormDropdown';

FormDropdown.propTypes = {
  menuItemData: PropTypes.array,
  props: PropTypes.object,
  touched: PropTypes.any,
  errors: PropTypes.any,
  inputProps: PropTypes.any,
  fieldWidth: PropTypes.any,
  dataCyAttributes: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};
