import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { handleRejectSuggestion, suggestionTargetTypesEnum, toastMessage } from 'helpers';
import { DocumentStateContext } from 'providers';
import { faCheck } from 'resources/fontawesome/regular/faCheck';
import { faCopy } from 'resources/fontawesome/regular/faCopy';
import { faFlag } from 'resources/fontawesome/regular/faFlag';
import { useStoreFields } from 'stores';

export const SimplificationActions = ({
  suggestionText,
  simplificationId,
  handleApplySuggestion,
  handleDialogClose,
  sentenceSuggestions,
  setSentenceSuggestions,
  trackMixpanelData,
  selectedText
}) => {
  const { connection } = useContext(DocumentStateContext);
  const {
    documentId,
    previouslyClickedSection,
    isRephraseSelected,
    setIsRephraseSelected,
    simplifications,
    setSelectedText
  } = useStoreFields([
    'documentId',
    'previouslyClickedSection',
    'isRephraseSelected',
    'setIsRephraseSelected',
    'simplifications',
    'setSelectedText'
  ]);

  const [t] = useTranslation('common');
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = suggestionText => {
    setShowTooltip(true);
    navigator?.clipboard?.writeText(suggestionText);

    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const handleRephrasedModalCopy = suggestionText => {
    navigator?.clipboard?.writeText(suggestionText);
    setIsRephraseSelected(false);
    setSelectedText(null);
    toastMessage(enqueueSnackbar, closeSnackbar, t('textCopied'), 'success');
  };

  const setParagraphSuggestions = (suggestions, rejectedReplacement) => {
    const filteredArray = suggestions?.filter(item => item.text !== rejectedReplacement);
    if (isRephraseSelected) {
      setSentenceSuggestions(filteredArray);
    } else {
      setSentenceSuggestions({
        ...simplifications,
        simplifications: filteredArray
      });
    }
  };

  const tooltipOffset = {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -10]
          }
        }
      ]
    }
  };

  const useStyles = makeStyles({
    simplificationActionsButton: {
      padding: 0,
      width: '32px',
      height: '32px'
    }
  });

  const classes = useStyles();

  return (
    <Stack direction='row' alignSelf='flex-end'>
      <Tooltip title={t('reject')} placement='top' slotProps={tooltipOffset}>
        <IconButton
          data-testid={`rejectSimplification-${simplificationId}`}
          className={classes.simplificationActionsButton}
          onClick={() => {
            handleRejectSuggestion(
              connection,
              documentId,
              suggestionText,
              selectedText || previouslyClickedSection.text,
              suggestionTargetTypesEnum.PARAGRAPH,
              sentenceSuggestions,
              setParagraphSuggestions
            );
            trackMixpanelData(true, suggestionText);
            sentenceSuggestions.length === 1 && handleDialogClose();
          }}>
          <FontAwesomeIcon icon={faFlag} style={{ width: '12px', height: '20px' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('copy')} placement='top-start' slotProps={tooltipOffset}>
        <div>
          {isRephraseSelected ? (
            <IconButton
              data-testid='copySimplification'
              className={classes.simplificationActionsButton}
              sx={{
                ml: 1,
                mr: '15px'
              }}
              onClick={() => handleRephrasedModalCopy(suggestionText)}>
              <FontAwesomeIcon icon={faCopy} style={{ width: '14px', height: '20px' }} />
            </IconButton>
          ) : (
            <Tooltip
              data-testid='simplificationCopiedTooltip'
              title={t('copied')}
              open={showTooltip}
              placement='top'
              slotProps={tooltipOffset}>
              <IconButton
                data-testid='copySimplification'
                className={classes.simplificationActionsButton}
                sx={{
                  ml: 1,
                  mr: '15px'
                }}
                onClick={() => handleCopy(suggestionText)}>
                <FontAwesomeIcon icon={faCopy} style={{ width: '14px', height: '20px' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Tooltip>
      <Tooltip title={t('apply')} placement='top' slotProps={tooltipOffset}>
        <IconButton
          data-testid={`applySimplification-${simplificationId}`}
          className={classes.simplificationActionsButton}
          onClick={() => {
            isRephraseSelected && setIsRephraseSelected(false);
            handleApplySuggestion(simplificationId, suggestionText);
          }}
          sx={{
            mr: '15px',
            '&.MuiIconButton-root': { backgroundColor: theme.palette.gray.dark }
          }}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{ width: '13px', height: '20px', color: theme.palette.gray.white }}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

SimplificationActions.propTypes = {
  suggestionText: PropTypes.string,
  simplificationId: PropTypes.string,
  handleApplySuggestion: PropTypes.func,
  handleDialogClose: PropTypes.func,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  trackMixpanelData: PropTypes.func,
  selectedText: PropTypes.string
};
