import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/StandardExclusions`;

const getStandardExclusions = async () => {
  return axios.get(baseUrl).then(res => {
    return res.data;
  });
};

const updateStandardExclusions = async standardExclusionsData => {
  const res = await axios.put(baseUrl, standardExclusionsData);
  return res.data;
};

export const standardExclusionService = {
  getStandardExclusions,
  updateStandardExclusions
};
