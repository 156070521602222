import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { PrimaryButton, TextFieldStyle } from 'components';
import { toastMessage } from 'helpers';
import { UserContext } from 'providers';
import { faXmark } from 'resources/fontawesome/regular/faXmark';

export const Rename = ({
  id,
  renameEntity,
  successMessage,
  isDialogOpen,
  setIsDialogOpen,
  text,
  entityName,
  setSelectedEntities,
  refetchEntity
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { refetchLabels } = useContext(UserContext);

  const submitRenameEntity = (values, { setSubmitting }) => {
    renameEntity(values).then(() => {
      refetchEntity();
      refetchLabels();
      toastMessage(enqueueSnackbar, closeSnackbar, t(successMessage), 'success');
    });
    setSubmitting(false);
    setIsDialogOpen(false);
    setSelectedEntities([]);
  };

  const useStyles = makeStyles({
    dialogTitle: {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamilyPrimarySemibold,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(16),
      padding: '0 0 25px 0'
    },
    dialogPaper: {
      borderRadius: '16px',
      width: '500px',
      padding: '30px 30px 20px 30px'
    }
  });
  const classes = useStyles();

  const renameEntitySchema = Yup.object().shape({
    name: Yup.string().max(255, t('tooLong')).trim().required(t('required'))
  });

  return (
    <Dialog
      data-testid='dialog'
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      maxWidth={'md'}
      classes={{ paper: classes.dialogPaper }}>
      <Grid container justifyContent='space-between' alignItems='top'>
        <DialogTitle className={classes.dialogTitle}>{t(text)}</DialogTitle>
        <FontAwesomeIcon
          data-testid='closeIcon'
          icon={faXmark}
          style={{
            color: theme.palette.gray.main,
            cursor: 'pointer',
            width: '1.5rem',
            height: '1.5rem'
          }}
          onClick={() => setIsDialogOpen(false)}
        />
      </Grid>
      <Formik
        initialValues={{ id: id, name: entityName }}
        validationSchema={renameEntitySchema}
        onSubmit={submitRenameEntity}>
        {({ touched, errors, getFieldProps, isSubmitting, isValid, dirty }) => (
          <Form>
            <Grid>
              <TextFieldStyle
                autoFocus
                data-cy={'renameInput'}
                data-testid='renameInput'
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{
                  '&.MuiFormControl-root': {
                    height: '50px',
                    width: '100%'
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '15px'
                  },
                  '& .Mui-focused': {
                    border: isValid && `1px solid ${theme.palette.gray.light}`,
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: isValid && 'none'
                    }
                  }
                }}
                InputProps={{
                  sx: {
                    fontFamily: theme.typography.fontFamilyPrimaryMedium,
                    color: theme.palette.gray.dark,
                    fontSize: theme.typography.pxToRem(14)
                  }
                }}
              />
            </Grid>
            <DialogActions sx={{ pt: '30px', pr: 0 }}>
              <PrimaryButton
                buttonText={t('save')}
                dataTestId='saveButton'
                type={'submit'}
                padding='15px 25px'
                loading={isSubmitting}
                isDisabled={isSubmitting || !(isValid && dirty)}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

Rename.propTypes = {
  id: PropTypes.number,
  entityName: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  text: PropTypes.string,
  setSelectedEntities: PropTypes.func,
  refetchEntity: PropTypes.func,
  renameEntity: PropTypes.func,
  successMessage: PropTypes.string
};
