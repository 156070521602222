'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
const prefix = 'far';
const iconName = 'arrow-turn-down-right';
const width = 512;
const height = 512;
const aliases = [];
const unicode = 'e3d6';
const svgPathData =
  'M48 56c0-13.3-10.7-24-24-24S0 42.7 0 56L0 224c0 48.6 39.4 88 88 88l342.1 0-87 87c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L505 305c9.4-9.4 9.4-24.6 0-33.9L377 143c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l87 87L88 264c-22.1 0-40-17.9-40-40L48 56z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};

exports.faArrowTurnDownRight = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
