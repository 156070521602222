'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
const prefix = 'fal';
const iconName = 'arrow-trend-up';
const width = 576;
const height = 512;
const aliases = [];
const unicode = 'e098';
const svgPathData =
  'M368 112c0-8.8 7.2-16 16-16l176 0c8.8 0 16 7.2 16 16l0 176c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-137.4L331.3 363.3c-6.2 6.2-16.4 6.2-22.6 0L192 246.6 27.3 411.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l176-176c6.2-6.2 16.4-6.2 22.6 0L320 329.4 521.4 128 384 128c-8.8 0-16-7.2-16-16z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};

exports.faArrowTrendUp = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
