import axios from 'axios';
import { getIdToken } from 'firebase/auth';

import { auth, getApiCoreUrl } from 'helpers';
import { SSO_TENANT, PROVIDER_KEY, TOKEN_MANAGER_KEY } from 'providers';

const isPing = 'PingId';

const getPingToken = (code, setTokenReceived) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: code,
      TenantName: localStorage.getItem(SSO_TENANT),
      AuthProvider: localStorage.getItem(PROVIDER_KEY)
    },
    url: `${getApiCoreUrl()}/api/v1/Login/token`
  };

  return axios(options).then(res => {
    const expiration = new Date().getTime() + res.data.expires_in * 1000;

    const tokenManager = {
      accessToken: res.data.access_token,
      expirationTime: expiration
    };

    setToken(tokenManager, setTokenReceived);
  });
};

const getAuthEndpoint = async () => {
  const options = {
    method: 'GET',
    headers: {
      TenantName: localStorage.getItem(SSO_TENANT),
      AuthProvider: localStorage.getItem(PROVIDER_KEY)
    },
    url: `${getApiCoreUrl()}/api/v1/Login/auth_endpoint`
  };
  return await axios(options);
};

const redirectToAuthEndpoint = async () => {
  const res = await tokenService.getAuthEndpoint();
  window.location = res.data;
};

const getToken = async isRequestFromFigma => {
  if (!isRequestFromFigma && getProvider() === isPing) {
    const tokenManager = getTokenManager();
    if (tokenManager && new Date().getTime() >= tokenManager['expirationTime']) {
      // if token is expired redirect to the ping login page and clear the session storage
      redirectToAuthEndpoint();
      localStorage.removeItem(TOKEN_MANAGER_KEY);
      localStorage.removeItem(PROVIDER_KEY);
    }

    return tokenManager['accessToken'];
  }

  const currentUser = auth?.currentUser;
  const token = currentUser && (await getIdToken(currentUser));

  return token;
};

const getTokenManager = () => {
  return JSON.parse(localStorage.getItem(TOKEN_MANAGER_KEY));
};

const extractTenantFromPath = path => {
  const parts = path.split('/');
  return parts[parts.length - 1];
};

const getProvider = () => {
  if (location.pathname.includes('/sso/')) {
    localStorage.setItem(PROVIDER_KEY, isPing);
    localStorage.setItem(SSO_TENANT, extractTenantFromPath(location.pathname));
  }

  return localStorage.getItem(PROVIDER_KEY);
};

const isPingUserLoggedIn = () => {
  return getProvider() === isPing && getTokenManager();
};

const setToken = (token, setTokenReceived) => {
  localStorage.setItem(TOKEN_MANAGER_KEY, JSON.stringify(token));
  setTokenReceived?.(true);
};

export const tokenService = {
  getPingToken,
  getToken,
  getProvider,
  isPingUserLoggedIn,
  getAuthEndpoint
};
