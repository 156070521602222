import React from 'react';

import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const SearchWrapper = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': {
    backgroundColor: `${theme.palette.gray[50]}`
  },
  '&.Mui-focused': {
    backgroundColor: `${theme.palette.gray.white} !important`
  },
  '&.Mui-focused fieldset': {
    borderColor: `${theme.palette.gray.dark} !important`
  },
  '&.MuiOutlinedInput-root': {
    color: theme.palette.gray.dark
  },
  '& input::placeholder': {
    color: theme.palette.gray[600],
    opacity: 1
  },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.gray[400]} !important`
  }
}));

export const SearchStyle = ({ dataCy, ...props }) => {
  return (
    <SearchWrapper {...props} inputProps={{ 'data-cy': dataCy, 'data-testid': 'searchStyle' }} />
  );
};

SearchStyle.propTypes = {
  dataCy: PropTypes.string
};
