import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, DialogTitle, Grid } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { faXmark } from 'resources/fontawesome/regular/faXmark';
import { faSparkles } from 'resources/fontawesome/solid/faSparkles';

export const ParagraphLevelSuggestionsDialogTitle = ({ handleDialogClose, title }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  return (
    <Grid
      direction='row'
      container
      justifyContent='space-between'
      alignItems='center'
      sx={{
        p: '0px 15px',
        borderBottom: `1px solid ${theme.palette.gray.light}`,
        height: '40px'
      }}>
      <DialogTitle
        sx={{
          p: 0,
          display: 'flex',
          alignItems: 'center'
        }}>
        <FontAwesomeIcon
          icon={faSparkles}
          style={{ color: theme.palette.rephrase.dark, width: '12px', height: '20px' }}
        />
        <Typography
          sx={{
            fontFamily: theme.typography.fontFamilyPrimarySemibold,
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.main,
            paddingLeft: '10px'
          }}>
          {t(title)}
        </Typography>
      </DialogTitle>
      <FontAwesomeIcon
        data-testid='closeDialog'
        icon={faXmark}
        style={{ color: theme.palette.gray.main, marginRight: '10px', cursor: 'pointer' }}
        onClick={handleDialogClose}
      />
    </Grid>
  );
};

ParagraphLevelSuggestionsDialogTitle.propTypes = {
  handleDialogClose: PropTypes.func,
  title: PropTypes.string
};
