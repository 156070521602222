import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CardActionArea } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons, LightTooltip } from 'components';
import { useStoreFields } from 'stores';

import { useStyles } from './DocumentEditorComponent.css.js';

export const ReloadSuggestionInlineButton = ({ topPosition, reloadEditedParagraphSuggestions }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [t] = useTranslation('common');

  const { isFetchingEditedParagraphSuggestions } = useStoreFields([
    'isFetchingEditedParagraphSuggestions'
  ]);

  return (
    <LightTooltip
      data-testid='tooltip'
      title={t('refreshSuggestions')}
      placement='bottom-start'
      enterDelay={3000}
      PopperProps={{
        sx: { display: isFetchingEditedParagraphSuggestions && 'none' }
      }}
      TransitionProps={{ timeout: 0 }}>
      <CardActionArea
        data-testid='reloadSuggestionsInlineBtn'
        className={classes.reloadParagraphSuggestions}
        sx={{ top: topPosition }}
        onClick={reloadEditedParagraphSuggestions}
        disabled={isFetchingEditedParagraphSuggestions}>
        <Box
          sx={{
            display: 'flex',
            padding: isFetchingEditedParagraphSuggestions ? '2px' : '3px'
          }}>
          <Icons
            iconName={
              isFetchingEditedParagraphSuggestions
                ? 'paragraphSuggestionsSpinner'
                : 'reloadSuggestions'
            }
            height={10}
            width={10}
            strokeColor={theme.palette.common.black}
          />
        </Box>
      </CardActionArea>
    </LightTooltip>
  );
};

ReloadSuggestionInlineButton.propTypes = {
  topPosition: PropTypes.number,
  reloadEditedParagraphSuggestions: PropTypes.func
};
