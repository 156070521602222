import React, { Suspense } from 'react';

import { LoadingScreen } from 'components';

// eslint-disable-next-line react/display-name
export const Loadable = Component => props => {
  return (
    <Suspense fallback={<LoadingScreen isLoading={true} />}>
      <Component {...props} />
    </Suspense>
  );
};
