import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Login`;
const apiUrl = import.meta.env?.VITE_API_URL;
const baseFigmaUrl = `${apiUrl}/api/v1/Login`;

const setTokenForFigmaPlugin = async id => {
  const url = `${baseUrl}/set_figma_token`;
  const res = await axios.post(url, id, {
    headers: {
      'content-type': 'application/json-patch+json'
    }
  });
  return res.data;
};

const pollingTokenForFigmaPlugin = async id => {
  const url = `${baseFigmaUrl}/get_figma_token/${id}`;
  const res = await axios.get(url);
  return res.data;
};

const getFigmaKeys = async () => {
  const url = `${baseFigmaUrl}/get_figma_keys`;
  const res = await axios.get(url);
  return res.data;
};

export const figmaPluginService = {
  setTokenForFigmaPlugin,
  pollingTokenForFigmaPlugin,
  getFigmaKeys
};
