import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { LoadingAnimation } from 'components';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.gray.white
}));

export const LoadingScreen = ({ isLoading, isLoaderWithMessage = false, ...other }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div data-testid='loadingScreen'>
      <RootStyle sx={{ height: isLoaderWithMessage ? '100%' : '100vh' }} {...other}>
        <LoadingAnimation height={150} width={150} />
      </RootStyle>
    </div>
  );
};

LoadingScreen.propTypes = {
  isLoading: PropTypes.bool,
  isLoaderWithMessage: PropTypes.bool
};
