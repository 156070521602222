import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { FormField, PrimaryButton } from 'components';
import { toastMessage } from 'helpers';
import { filterSetService } from 'services';

export const SaveFilterSetDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  filterSetData,
  page,
  filterSets,
  text = 'newFilterSet',
  isRename = false,
  id,
  refetchFilterSets
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSave = (values, { setSubmitting }) => {
    const filterData = {
      name: values.name,
      source: page,
      filterState: JSON.stringify(filterSetData)
    };

    if (isRename) {
      filterSetService.updateFilterSet(id, values.name, '/name').then(() => {
        toastMessage(enqueueSnackbar, closeSnackbar, t('successFilterSetRename'), 'success');
        refetchFilterSets();
      });
    } else {
      filterSetService.saveFilterSet(filterData).then(() => {
        toastMessage(enqueueSnackbar, closeSnackbar, t('successFilterSetSave'), 'success');
        refetchFilterSets();
      });
    }

    setIsDialogOpen(false);
    setSubmitting(false);
  };

  const useStyles = makeStyles({
    closeIcon: {
      width: '20px',
      height: '20px',
      backgroundColor: theme.palette.gray.lighter,
      borderRadius: '50%',
      padding: '0.15rem',
      cursor: 'pointer',
      color: theme.palette.gray.main,
      marginRight: '12px'
    },
    dialogTitle: {
      color: theme.palette.primary.dark,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      fontSize: theme.typography.pxToRem(16)
    },
    dialogPaper: {
      borderRadius: '10px',
      width: '550px',
      padding: '20px'
    }
  });
  const classes = useStyles();

  const saveFilterSetSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, t('tooLong'))
      .trim()
      .required(t('required'))
      .test('isUnique', t('nonUniqueName'), value => {
        return !filterSets.some(filterSet => filterSet.name === value);
      })
  });

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      maxWidth={'md'}
      classes={{ paper: classes.dialogPaper }}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <DialogTitle className={classes.dialogTitle}>{t(text)}</DialogTitle>
        <CloseIcon onClick={() => setIsDialogOpen(false)} className={classes.closeIcon} />
      </Grid>
      <Formik
        initialValues={{ id: id, name: '' }}
        validationSchema={saveFilterSetSchema}
        onSubmit={handleSave}>
        {({ touched, errors, getFieldProps, isSubmitting, isValid, dirty }) => (
          <Form>
            <div style={{ padding: '16px 24px' }}>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  fontSize: theme.typography.pxToRem(14),
                  color: theme.palette.gray.main
                }}>
                {t('name')}
              </Typography>
              <FormField
                autoFocus
                dataCy='filterSetNameInput'
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                sx={{
                  '&.MuiFormControl-root': {
                    marginTop: '15px',
                    height: '50px',
                    width: '100%'
                  }
                }}
              />
            </div>
            <DialogActions sx={{ p: 2, pt: 4 }}>
              <PrimaryButton
                buttonText={t('save')}
                dataCy='saveFilterSetButton'
                type={'submit'}
                loading={isSubmitting}
                isDisabled={isSubmitting || !(isValid && dirty)}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

SaveFilterSetDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  filterSetData: PropTypes.object,
  page: PropTypes.number,
  filterSets: PropTypes.array,
  text: PropTypes.string,
  isRename: PropTypes.bool,
  id: PropTypes.number,
  refetchFilterSets: PropTypes.func
};
