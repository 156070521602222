'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
const prefix = 'fal';
const iconName = 'chart-simple';
const width = 448;
const height = 512;
const aliases = [];
const unicode = 'e473';
const svgPathData =
  'M240 64c8.8 0 16 7.2 16 16l0 352c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-352c0-8.8 7.2-16 16-16l32 0zM208 32c-26.5 0-48 21.5-48 48l0 352c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-352c0-26.5-21.5-48-48-48l-32 0zM80 256c8.8 0 16 7.2 16 16l0 160c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-160c0-8.8 7.2-16 16-16l32 0zM48 224c-26.5 0-48 21.5-48 48L0 432c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-160c0-26.5-21.5-48-48-48l-32 0zm320-96l32 0c8.8 0 16 7.2 16 16l0 288c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-288c0-8.8 7.2-16 16-16zm-48 16l0 288c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48l-32 0c-26.5 0-48 21.5-48 48z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};

exports.faChartSimple = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
