import axios from 'axios';

import { collectIds, getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/CustomExclusions`;

const getCustomExclusions = async (filterData, search, orderBy) => {
  const exclusionOptionsIds = collectIds(filterData?.exclusions?.exclusions);
  const filters = [
    { property: 'search', value: search },
    { property: 'orderBy', value: orderBy },
    { property: 'exclusionOptions', value: exclusionOptionsIds }
  ];

  return axios.post(baseUrl, filters).then(res => {
    return res.data;
  });
};

const getCustomExclusion = async id => {
  const url = `${baseUrl}/${id}`;
  return axios.get(url).then(response => {
    return response.data;
  });
};

const createCustomExclusion = data => {
  const url = `${baseUrl}/create`;
  return axios.post(url, data);
};

const updateCustomExclusion = async customExclusionData => {
  const url = `${baseUrl}/${customExclusionData.id}`;
  const res = await axios.put(url, customExclusionData);
  return res.data;
};

const deleteExclusions = data => {
  const ids = data.map(d => `ids=${d}`);
  const url = `${baseUrl}?${ids.join('&')}`;
  return axios.delete(url);
};

export const customExclusionService = {
  getCustomExclusions,
  getCustomExclusion,
  createCustomExclusion,
  updateCustomExclusion,
  deleteExclusions
};
