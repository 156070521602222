import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { useCollapseDrawer } from 'hooks';
import { faXmark } from 'resources/fontawesome/regular/faXmark';
import './LabelChips.css';

const CHIP_FONT_SIZE = 12;
const CHIP_GAP = 8;
const CHIP_LABEL_PADDING = 12;
const CIRCLE_SIZE = 25;
const DELETE_ICON_WIDTH = 12;

const useStyles = makeStyles(theme => ({
  circleItem: {
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    minWidth: CIRCLE_SIZE,
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: '1px solid',
    color: theme.palette.gray.main,
    borderColor: theme.palette.gray.light,
    '&:hover': {
      backgroundColor: theme.palette.gray.light,
      color: theme.palette.gray.darker
    }
  },
  circleFont: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamilyPrimaryMedium
  },
  chip: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '25px',
    background: theme.palette.gray.lighter
  },
  chipFont: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    color: theme.palette.gray.main,
    fontSize: theme.typography.pxToRem(CHIP_FONT_SIZE)
  },
  popoverLabel: {
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12)
  }
}));

export const LabelChips = React.memo(({ labels, onDelete, opacity = 1 }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { isCollapse } = useCollapseDrawer();
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [labelsWidth, setLabelsWidth] = useState(CHIP_GAP + CIRCLE_SIZE);

  const hiddenChipIds = [];
  let occupiedWidth = 0;

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref.current]);

  useEffect(() => setTimeout(handleResize, 900), [isCollapse]);

  const estimateTextWidth = text => {
    const context = document.createElement('canvas').getContext('2d');
    if (context) {
      context.font = `${CHIP_FONT_SIZE}px ${theme.typography.fontFamilyPrimaryRegular}`;
      context.letterSpacing = '0.115px';
      return context.measureText(text).width;
    }
  };

  const shouldChipBeDisplayed = (label, index) => {
    occupiedWidth +=
      estimateTextWidth(label.name) +
      2 * CHIP_LABEL_PADDING +
      (index === 0 ? 0 : CHIP_GAP) +
      (onDelete ? DELETE_ICON_WIDTH : 0);

    const maxWidth = labelsWidth - CHIP_GAP - (index === labels.length - 1 ? 0 : CIRCLE_SIZE);

    if (occupiedWidth < maxWidth || index === 0) {
      return true;
    }

    hiddenChipIds.push(label.id);
    return false;
  };

  const handleResize = () => setLabelsWidth(ref.current?.offsetWidth);

  return (
    <Box
      ref={ref}
      gap={`${CHIP_GAP}px`}
      display='flex'
      flexWrap='nowrap'
      flexDirection='row'
      alignItems='center'
      data-testid='labelChips'
      sx={{ opacity: opacity }}>
      {labels.map(
        (label, index) =>
          shouldChipBeDisplayed(label, index) && (
            <Chip
              key={label.id}
              className={classes.chip}
              onDelete={onDelete ? () => onDelete(label.id) : null}
              deleteIcon={
                <FontAwesomeIcon
                  icon={faXmark}
                  width='9px'
                  data-testid='closeIcon'
                  style={{ color: theme.palette.gray.main, marginRight: '9px' }}
                  onMouseDown={e => e.stopPropagation()}
                />
              }
              label={<Typography className={classes.chipFont}>{label.name}</Typography>}
            />
          )
      )}
      {hiddenChipIds.length > 0 && (
        <div
          onMouseEnter={e => setAnchorEl(e.target)}
          onMouseLeave={() => setAnchorEl(null)}
          className={classes.circleItem}>
          <Typography className={classes.circleFont}>+{hiddenChipIds.length}</Typography>
          {!!anchorEl && (
            <Popover
              open
              anchorEl={anchorEl}
              sx={{ pointerEvents: 'none' }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  mt: theme.spacing(0.5),
                  p: '15px',
                  bgcolor: theme.palette.gray.white,
                  minWidth: '120px'
                }
              }}>
              {labels.map(
                label =>
                  hiddenChipIds.includes(label.id) && (
                    <Typography key={label.id} className={classes.popoverLabel}>
                      {label.name}
                    </Typography>
                  )
              )}
            </Popover>
          )}
        </div>
      )}
    </Box>
  );
});

LabelChips.displayName = 'LabelChips';

LabelChips.propTypes = {
  labels: PropTypes.array,
  onDelete: PropTypes.func,
  opacity: PropTypes.number
};
