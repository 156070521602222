const path = link => {
  return `${link}`;
};

export const AUTH_PATHS = {
  LOGIN: path('/signin'),
  REGISTER: path('/setup_account'),
  FORGOT_PASSWORD: path('/forgot_password'),
  RESET_PASSWORD: path('/reset-password'),
  MFA_LOGIN: path('/mfa_login'),
  MFA_REGISTER: path('/mfa_register')
};

export const APP_PATHS = {
  CUSTOM_EXCLUSIONS: path('/style_guide/exclusions/custom'),
  CUSTOM_EXCLUSIONS_CREATE: path('/style_guide/exclusions/custom/create'),
  DASHBOARD: path('/dashboard'),
  REPORTING: path('/reporting'),
  INTELLIGIBILITY: path('/reporting/intelligibility'),
  PRODUCTIVITY: path('/reporting/productivity'),
  RISK: path('/reporting/risk'),
  DOCUMENTS: path('/content'),
  SETTINGS: path('/settings'),
  STANDARD_EXCLUSIONS: path('/style_guide/exclusions/standard'),
  USERS: path('/settings/users'),
  LABELS: path('/settings/labels'),
  STYLE_GUIDE: path('/style_guide'),
  PROFILE: path('/profile')
};
