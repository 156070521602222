'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
const prefix = 'far';
const iconName = 'underline';
const width = 448;
const height = 512;
const aliases = [];
const unicode = 'f0cd';
const svgPathData =
  'M16 56c0-13.3 10.7-24 24-24l96 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-24 0 0 144c0 61.9 50.1 112 112 112s112-50.1 112-112l0-144-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l96 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-24 0 0 144c0 88.4-71.6 160-160 160s-160-71.6-160-160L64 80 40 80C26.7 80 16 69.3 16 56zM0 456c0-13.3 10.7-24 24-24l400 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L24 480c-13.3 0-24-10.7-24-24z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};

exports.faUnderline = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
