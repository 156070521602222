import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { faArrowTurnLeft } from 'resources/fontawesome/regular/faArrowTurnLeft';

const useStyles = makeStyles({
  defaultStyles: props => ({
    cursor: 'pointer',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    backgroundColor: props.palette.gray.lighter,
    '&:hover': {
      backgroundColor: props.palette.gray.light
    }
  }),
  label: props => ({
    fontFamily: props.typography.fontFamilySecondaryMedium,
    fontSize: props.typography.pxToRem(12),
    color: props.palette.common.black
  })
});

export const BackButton = ({
  handleClick,
  label,
  customStyles,
  height = '16px',
  width = '16px'
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Stack direction='row' justifyContent='flex-start' alignItems='center'>
      <Box
        className={clsx([classes.defaultStyles, customStyles])}
        onClick={handleClick}
        data-testid='backButton'
        data-cy='backButton'>
        <FontAwesomeIcon
          icon={faArrowTurnLeft}
          color={theme.palette.primary.main}
          width={width}
          height={height}
        />
      </Box>
      <Typography className={classes.label}>{label}</Typography>
    </Stack>
  );
};

BackButton.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  customStyles: PropTypes.string
};
